import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private interval: number;

  constructor(public loadingController: LoadingController) {}

  dismiss() {
    this.loadingController.dismiss().then().catch();
  }

  show() {
    this.loadingController
      .create({
        message: "Patientez svp...",
      })
      .then((loader) => {
        loader
          .present()
          .then((_) => _)
          .catch((_) => _);
      })
      .catch((_) => _);
  }

  stop() {
    this.interval = window.setInterval(() => {
      this.checkAndClose()
        .then((_) => _)
        .catch((_) => _);
    }, 100);
  }

  async checkAndClose() {
    const overlay = await this.loadingController.getTop();
    try {
      if (overlay) {
        overlay
          .dismiss()
          .then(() => {
            window.clearInterval(this.interval);
          })
          .catch();
      }
    } catch (error) {
    } finally {
      overlay
        ?.dismiss()
        .then(() => {})
        .catch();
    }
  }
}
