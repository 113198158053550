import { LoginDto } from "src/app/api/auth/auth";

import { createAction, props } from "@ngrx/store";

import { CountryConfig } from "../../common/types";
import { User } from "../user/user";

export const getCountry = createAction("[App] Get country");
export const getCountrySuccess = createAction(
  "[App] Get country success",
  props<{ country: CountryConfig }>()
);
export const getMe = createAction(
  "[App] Get me",
  props<{ goHome?: boolean }>()
);
export const getMeSuccess = createAction(
  "[App] Get me success",
  props<{ user: User }>()
);

export const login = createAction("[App] Login", props<{ dto: LoginDto }>());
