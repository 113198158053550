const server = "https://doon-api-dev-09d8b6c876bb.herokuapp.com";
export const environment = {
  production: false,
  countryCode: "ci",
  isWeb: false,
  server,
  cpK: "696297544611a72a3237119.53867449",
  si: 276377,
  nu: server + "/donations/wb/cinetpay",
  cmode: "PRODUCTION",
  firebase: {
    apiKey: "AIzaSyAPA-n9x57IFmbPYen2-duOoG4XNXeXV84",
    authDomain: "doon-ci-dev.firebaseapp.com",
    projectId: "doon-ci-dev",
    storageBucket: "doon-ci-dev.appspot.com",
    messagingSenderId: "233690728703",
    appId: "1:233690728703:web:296c9f043697d2461324ca",
  },
};
