

import { BehaviorSubject, Observable, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { COUNTRY_CODE } from '../app.config';
import { CountryConfig } from '../common/types';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  readonly country$ = new BehaviorSubject<CountryConfig | undefined>(undefined);


  constructor(
    private http: HttpClient,
    @Inject(COUNTRY_CODE) readonly countryCode: string, 
    private readonly translateService: TranslateService
    ) { }

  getCountry(code?: string): Observable<CountryConfig> {
    return this.http.get<CountryConfig>(`/countries/${code || this.countryCode}`).pipe(
      tap({
        next: (country) => {
           this.country$.next(country);
           this.translateService.use(country.languages[0]);
        }
      })
    )
  }
}
