import { EMPTY } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { AppService } from "../app.service";
import { getCountry, getCountrySuccess } from "./app.actions";

@Injectable()
export class getCountryEffects {
  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCountry),
      exhaustMap((action) =>
        this.service.getCountry().pipe(
          map((country) => getCountrySuccess({ country })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private service: AppService) {}
}
