import { iif, Observable, switchMap, throwError } from "rxjs";
import { Address } from "src/app/common/types";
import { CommonResponseBody } from "src/app/common/types/responses/response";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { CreateUserDto } from "./create-user.dto";
import {
  ApiUser,
  ChangeEmailDto,
  ChangePasswordDto,
  CheckEmailResponse,
  RequestDeleteUserDto,
  ResetPasswordDto,
} from "./user";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  readonly path = "/users";

  constructor(private readonly http: HttpClient) {}

  isEmailUsed(email: string): Observable<CheckEmailResponse> {
    const params = new HttpParams({
      fromObject: {
        email,
      },
    });
    return this.http.get<CheckEmailResponse>(`${this.path}/email-used`, {
      params,
    });
  }

  getEmailOtp(email: string) {
    const params = new HttpParams({
      fromObject: {
        email,
      },
    });
    return this.http.get(`${this.path}/email-otp`, { params });
  }

  verifyEmailOtp(email: string, code: string): Observable<CommonResponseBody> {
    const params = new HttpParams({
      fromObject: {
        email,
        code,
      },
    });
    return this.http.get<CommonResponseBody>(`${this.path}/verify-email-otp`, {
      params,
    });
  }

  getOtp(email: string): Observable<any> {
    return this.isEmailUsed(email).pipe(
      switchMap((res) =>
        iif(
          () => res.used,
          throwError(() => new Error("Email déjà utilisé")),
          this.getEmailOtp(email)
        )
      )
    );
  }

  create(body: CreateUserDto) {
    return this.http.post<any>(`${this.path}/create`, body);
  }

  getMe() {
    const url = `${this.path}/me`;
    return this.http.get<ApiUser>(`${url}`);
  }

  getById(id: string) {
    const url = `${this.path}/find-by-id/${id}`;
    return this.http.get<ApiUser>(`${url}`);
  }

  getResetPasswordOtp(email: string) {
    const params = new HttpParams({
      fromObject: {
        email,
      },
    });

    const url = `${this.path}/password/reset-otp`;
    return this.http.get<CommonResponseBody>(`${url}`, { params });
  }

  resetPassword(body: ResetPasswordDto) {
    return this.http.post<CommonResponseBody>(
      `${this.path}/password/reset`,
      body
    );
  }

  changePassword(body: ChangePasswordDto) {
    return this.http.put<CommonResponseBody>(
      `${this.path}/password/change`,
      body
    );
  }

  changeEmailGetOtp(newEmail: string) {
    const params = new HttpParams({
      fromObject: {
        newEmail,
      },
    });

    return this.http.get<CommonResponseBody>(
      `${this.path}/change-email/get-otp`,
      {
        params,
      }
    );
  }

  changeEmailSubmit(body: ChangeEmailDto): Observable<CommonResponseBody> {
    return this.http.put<CommonResponseBody>(
      `${this.path}/change-email/submit`,
      body
    );
  }

  changeAddress(body: Address) {
    return this.http.put<CommonResponseBody>(
      `${this.path}/change-address`,
      body
    );
  }

  deleteAccount(body: RequestDeleteUserDto) {
    return this.http.post<CommonResponseBody>(
      `${this.path}/request-deletion`,
      body
    );
  }
}
