import { EMPTY } from "rxjs";
import { catchError, exhaustMap, tap } from "rxjs/operators";
import { AuthApiService } from "src/app/api/auth/auth-api.service";
import { AuthService } from "src/app/common/services";
import { ExceptionMessages } from "src/app/common/types/responses/exception-messages";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { login } from "./app.actions";

@Injectable()
export class loginEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        exhaustMap((action) =>
          this.authApiService.login(action.dto).pipe(
            tap(({ access_token }) => {
              this.authService
                .setAccessToken(access_token)
                .then(() => {
                  this.authApiService.errorMessage$.next("");
                  this.router.navigate(["/main"]);
                })
                .catch(() => {
                  this.authApiService.errorMessage$.next(
                    "Veuillez ressayez, un problème est survenu"
                  );
                });
            }),
            catchError((e) => {
              if (e?.message === ExceptionMessages.LOGIN_FORBIDDEN) {
                this.authApiService.errorMessage$.next(
                  "Vous avez désactivé votre compte"
                );
              } else {
                this.authApiService.errorMessage$.next(
                  "Identitifants incorrects"
                );
              }
              return EMPTY;
            })
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private authApiService: AuthApiService,
    private authService: AuthService
  ) {}
}
