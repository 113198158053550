import { inject } from "@angular/core";
import { CanActivateFn, RedirectCommand, Router } from "@angular/router";

import { StorageService } from "../services/storage/storage.service";

export const startupGuard: CanActivateFn = async (route, state) => {
  const storageService = inject(StorageService);
  const router = inject(Router);

  const alreadyLaunched: boolean = await storageService.storage?.get(
    "already_launched"
  );

  if (alreadyLaunched) {
    const mainPath = router.parseUrl("/main/home");
    return new RedirectCommand(mainPath, {
      skipLocationChange: true,
    });
  } else {
    storageService.storage?.set("already_launched", true);
    return true;
  }
};
