import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(readonly storage: Storage) {
    this.initStorage();
  }

  async initStorage() {
    await this.storage.create();
  }
}
