import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

import { ApiResponse } from "../types";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  constructor(public toastController: ToastController) {}

  handleMessage(response: ApiResponse) {
    this.showMessage(response);
  }

  async showMessage(response: ApiResponse) {
    if (!response) {
      return;
    }

    if (response.code === 0) {
      return;
    }
    const toast = await this.toastController.create({
      message: response.description || response.message,
      animated: true,
      position: "top",
      duration: 5000,
      color: "danger",
    });
    toast.present();
  }

  async show(
    message: string,
    color: "success" | "danger" | "warning" = "success"
  ) {
    const toast = await this.toastController.create({
      message,
      animated: true,
      position: "top",
      duration: 3000,
      color,
    });
    toast.present();
  }
}
