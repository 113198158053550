import { NgxPermissionsService } from "ngx-permissions";
import { EMPTY } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { UserApiService } from "src/app/api/user/user-api.service";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { getMe, getMeSuccess } from "./app.actions";

@Injectable()
export class getMeEffects {
  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMe),
      exhaustMap((action) =>
        this.service.getMe().pipe(
          tap((user) => {
            this.permissionsService.loadPermissions(user.permissions);
          }),
          map((user) => getMeSuccess({ user })),
          tap(() => {
            if (action.goHome) {
              this.router.navigate(["/main"]);
            }
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private service: UserApiService,
    private permissionsService: NgxPermissionsService
  ) {}
}
