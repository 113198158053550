import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

import { StorageService } from "./storage/storage.service";

export const ACCESS_TOKEN_KEY = "access_token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    readonly jwtHelper: JwtHelperService,
    private readonly storageService: StorageService
  ) {}

  setAccessToken(token: string): Promise<any> {
    return this.storageService.storage?.set(ACCESS_TOKEN_KEY, token);
  }

  getAccessToken() {
    return this.storageService.storage?.get(ACCESS_TOKEN_KEY);
  }

  async logout() {
    await this.storageService.storage?.remove(ACCESS_TOKEN_KEY);
  }

  async hasValidAccessToken() {
    const at = await this.getAccessToken();
    const tokenExpied = await this.jwtHelper.isTokenExpired();
    return !!at && !tokenExpied;
  }
}
