import { createReducer, on } from "@ngrx/store";

import { CountryConfig } from "../../common/types";
import { User } from "../user/user";
import { getCountrySuccess, getMeSuccess } from "./app.actions";

export interface AppState {
  country?: CountryConfig;
  user?: User;
}

export const initialState: AppState = {};

export const appReducer = createReducer(
  initialState,
  on(getCountrySuccess, (state, { country }) => ({ ...state, country })),
  on(getMeSuccess, (state, { user }) => ({ ...state, user }))
);
