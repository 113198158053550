import { Component, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { App } from "@capacitor/app";
import { StatusBar } from "@capacitor/status-bar";
import { AlertController, Platform } from "@ionic/angular";
import { IonApp, IonImg, IonRouterOutlet } from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";

import { getMe } from "./core/state/app.actions";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  imports: [IonImg, IonRouterOutlet, IonApp, FormsModule],
  providers: [],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private store: Store,
    public alertController: AlertController,
    public translateService: TranslateService,
    public readonly router: Router
  ) {
    StatusBar.setOverlaysWebView({ overlay: true });

    this.translateService.setDefaultLang("en");
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.routerOutlet.canGoBack()) {
        App.exitApp();
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart && event.url.includes("/main")) {
          this.store.dispatch(getMe({}));
        }

        if (event instanceof NavigationEnd) {
          // hide or show
          const urlToHide = ["/startup"];
          if (urlToHide.includes(event.url)) {
            StatusBar.hide();
          } else {
            StatusBar.show();
          }
        }
      },
    });
  }
}
