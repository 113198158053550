import { NgxPermissionsModule } from "ngx-permissions";
import { environment } from "src/environments/environment";

import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  InjectionToken,
} from "@angular/core";
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from "@angular/router";
import { JWT_OPTIONS, JwtModule } from "@auth0/angular-jwt";
import { IonicRouteStrategy } from "@ionic/angular";
import { provideIonicAngular } from "@ionic/angular/standalone";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { provideEffects } from "@ngrx/effects";
import { provideStore, Store } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { ApiInterceptor } from "./api/api-interceptor.";
import { routes } from "./app.routes";
import { ACCESS_TOKEN_KEY } from "./common/services";
import { StorageService } from "./common/services/storage/storage.service";
import { getCountry } from "./core/state/app.actions";
import { appReducer } from "./core/state/app.state";
import { getCountryEffects } from "./core/state/get-country.effects";
import { getMeEffects } from "./core/state/get-me.effects";
import { loginEffects } from "./core/state/login.effects";

export const COUNTRY_CODE = new InjectionToken<string>("COUNTRY_CODE");
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function initializeAppFactory(store: Store): () => void {
  return () => {
    store.dispatch(getCountry());
  };
}

export function jwtOptionsFactory(storage: StorageService) {
  return {
    tokenGetter: () => {
      return storage.storage?.get(ACCESS_TOKEN_KEY);
    },
    allowedDomains: [environment.server],
    disallowedRoutes: ["http://localhost:3000/assets"],
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([ApiInterceptor])
    ),
    importProvidersFrom(
      JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [StorageService],
        },
      })
    ),
    {
      provide: COUNTRY_CODE,
      useValue: environment.countryCode,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [Store],
    },
    provideRouter(
      routes,
      withPreloading(PreloadAllModules), // https://web.dev/articles/route-preloading-in-angular
      withRouterConfig({
        onSameUrlNavigation: "reload",
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
      })
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideStore({ app: appReducer }),
    provideEffects(getCountryEffects, getMeEffects, loginEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !environment.production,
      autoPause: true,
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgxPermissionsModule.forRoot(),
      IonicStorageModule.forRoot({
        name: "__doonci",
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      })
    ),
  ],
};
