import { inject } from "@angular/core";
import { CanActivateFn, RedirectCommand, Router } from "@angular/router";

import { AuthService } from "./auth.service";

export const AuthGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const hasValidToken = await authService.hasValidAccessToken();

  if (!hasValidToken) {
    await authService.logout();
    const loginPath = router.parseUrl("/login");
    return new RedirectCommand(loginPath, {
      skipLocationChange: true,
    });
  }
  return true;
};
