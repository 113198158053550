import { Subject } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { LoginDto } from "./auth";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  readonly path = "/auth";

  readonly errorMessage$ = new Subject<string>();

  constructor(private readonly http: HttpClient) {}

  login(body: LoginDto) {
    return this.http.post<{
      access_token: string;
    }>(`${this.path}/login`, body);
  }
}
