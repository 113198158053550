import { throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { COUNTRY_CODE } from "src/app/app.config";
import { environment } from "src/environments/environment";

import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { Http } from "../common/constants";
import { AuthService } from "../common/services";
import { LoaderService } from "../common/services/loader.service";
import { MessageService } from "../common/services/message.service";
import { ApiResponse } from "../common/types";
import { ResponseMessages } from "../common/types/responses/messages";

export const ApiInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const redirectToLogin = () => {
    router.navigate(["/login"]);
  };
  const isRequestFromMain = () => {
    return router.url.includes("/main");
  };

  const handleError = (httpError: HttpErrorResponse) => {
    const apiError: ApiResponse = httpError.error;

    if (httpError.status === 0) {
      // navigate to a page showing there is a connection issue
      console.error("A client-side or network error occurred", httpError.error);
    } else {
      if (httpError.status === Http.Unauthorized && isRequestFromMain()) {
        redirectToLogin();
      }
    }
    // messageService.handleMessage(apiError );
    return throwError(() => apiError);
  };

  if (req.url.includes("assets")) {
    return next(req);
  }

  const router = inject(Router);
  const loaderService = inject(LoaderService);
  const messageService = inject(MessageService);
  const authService = inject(AuthService);
  const countryCode = inject(COUNTRY_CODE);

  // loaderService.show();

  req = req.clone({
    url: `${environment.server}${req.url}`,
    headers: req.headers.set("X-Country-Code", countryCode),
  });

  if (isRequestFromMain() && !authService.hasValidAccessToken()) {
    redirectToLogin();
  }

  return next(req).pipe(
    map((event) => {
      if (event instanceof HttpResponse) {
        const body = event.body as ApiResponse;
        const { data, message } = body;

        if (message !== ResponseMessages.OPERATION_SUCCES) {
          throwError(() => event);
        }

        return event.clone({
          body: data,
        });
      }

      return event;
    }),
    catchError(handleError),
    finalize(() => {
      // loaderService.stop();
    })
  );
};
