import { HttpStatusCode } from "@angular/common/http";

export enum ExceptionMessages {
  // USERS
  USER_NOT_FOUND = "USER_NOT_FOUND",
  LOGIN_FORBIDDEN = "LOGIN_FORBIDDEN",
  // POTS
  POT_NOT_FOUND = "POT_NOT_FOUND",
  POT_NOT_ACTIVE = "POT_NOT_ACTIVE",
  POT_MAXIMUM_AMOUNT_REACHED = "POT_MAXIMUM_AMOUNT_REACHED",
  POT_ID_INVALID = "POT_ID_INVALID",
  HAS_ACTIVE_POT = "HAS_ACTIVE_POT",
  HAS_NOT_WITHDRAWN_POT = "HAS_NOT_WITHDRAWN_POT",
  HAS_PAYOUT_REQUEST = "HAS_PAYOUT_REQUEST",

  // DONATIONS
  DONATION_NOT_FOUND = "DONATION_NOT_FOUND",

  PAYMENT_SERVICE_NOT_FOUND = "PAYMENT_SERVICE_NOT_FOUND",
  MISSING_COUNTRY_CODE = "MISSING_COUNTRY_CODE",

  VERIFICATION_CODE_ALREADY_SENT = "VERIFICATION_CODE_ALREADY_SENT",
  VERIFICATION_CODE_INCORRECT = "INCORRECT_VERIFICATION_CODE",

  UNVERIFIED_EMAIL = "UNVERIFIED_EMAIL",
  EMAIL_ALREADY_REGISTRED = "EMAIL_ALREADY_REGISTRED",
  PHONE_ALREADY_REGISTRED = "PHONE_ALREADY_REGISTRED",

  OTP_EXPIRED = "OTP_EXPIRED",
  OTP_INCORRECT = "OTP_INCORRECT",

  // COMMON MESSAGES
  MISSING_PARAM = "MISSING_PARAM",
  INVALID_PARAM = "INVALID_PARAM",

  INVALID_QUERY_PARAM = "INVALID_QUERY_PARAM",
  MISSING_QUERY_PARAM = "MISSING_QUERY_PARAM",

  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  OPERATION_ERROR = "OPERATION_ERROR",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  MUST_PROVIDE_NEW_PASSWORD = "MUST_PROVIDE_NEW_PASSWORD",
  COULD_NOT_CREATE_TOKEN = "COULD_NOT_CREATE_TOKEN",

  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",

  ACCEPT_TERMS_REQUIRED = "ACCEPT_TERMS_REQUIRED",
  MUST_PROVIDE_NEW_EMAIL = "MUST_PROVIDE_NEW_EMAIL",

  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",

  PAYMENT_FAILED = "PAYMENT_FAILED",
  SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",

  PAYOUT_SERVICE_NOT_FOUND = "PAYOUT_SERVICE_NOT_FOUND",

  INVALID_AMOUNT = "INVALID_AMOUNT",
  ERROR_CURRENCY_NOT_VALID = "ERROR_CURRENCY_NOT_VALID",
}

export class ExceptionResponse {
  readonly statusCode: HttpStatusCode;
  readonly message: ExceptionMessages;
  readonly error: any | null;
}
